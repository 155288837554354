import FirstScreen from "~/components/Enga/Components/FirstScreen";
import AutopromoContainer from "~/components/Enga/Components/AutopromoContainer";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";

import "./EngaPages.css";
import "./EngaSinglePage.css";

import IconPlayArrow from "~/img/icons/play_arrow.svg";
import IconArrowDownward from "~/img/icons/arrow_downward.svg";
import IconArrowForward from "~/img/icons/arrow_forward.svg";
import GtmVars from "../shared/Trackers/GtmVars";

export default function Enga1Page() {
  const gtmValues = () => {
    return {
      "all.pageType": "other",
      "all.mainCategory": "Engagement 1",
      "all.category": "Non défini",
      "all.subCategory": "Non défini",
      "all.subsubCategory": "Non défini",
      "all.subsubsubCategory": "Non défini",
      template: "nos-engagements",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article class="page-engagement page-engagement-1">
        <FirstScreen
          visual="/images/engagements/page-engagement-1-visual.jpg"
          visualMobile="/images/engagements/page-engagement-1-visual-mobile.jpg"
          title="Une meilleure qualité de l’air"
          prevLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/"
          nextLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/le-confort-d-ete.html"
          isFirstEnga
        />

        <EngaDetails />
        <section class="engagement-rows">
          <h2>Les preuves de nos engagements</h2>
          <EngaRow1 />
          <EngaRow2 />
          <EngaRow3 />
        </section>
        <VideoExcerpt />

        <section class="next-nav">
          <a
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/le-confort-d-ete.html",
            )}
            class="btn"
          >
            Voir l'engagement suivant{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </section>
      </article>

      <AutopromoContainer />
    </>
  );
}

function EngaDetails() {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <section class="engagement-details">
        <div class="introduction">
          <div class="tags">
            <span>Pour votre santé</span>
          </div>
          <p>
            Construire des biens qui font du bien, c’est notamment intégrer dans
            100% de nos logements des{" "}
            <strong>
              systèmes de ventilation renforcée et des peintures labellisées
            </strong>{" "}
            qui limitent les émissions dans le respect des normes NF Habitat.
          </p>
        </div>

        <div class="media-part">
          <div class="visual">
            <img
              fetchpriority="high"
              src={imgProxy(
                "/images/engagements/page-engagement-1-video.jpg",
                `size:690:442/resizing_type:fill`,
              )}
              alt=""
              height="240"
              width="375"
              loading="lazy"
            />
            <button
              class="btn btn-icon"
              onClick={() => {
                setMediaOverlay(
                  "embed",
                  `<iframe height="100%" width="100%" src="https://www.youtube.com/embed/tQGsKnotBC8" allowfullscreen />`,
                );
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPlayArrow />
              </i>
            </button>
          </div>
          <div class="video-details">
            <p class="blockquote-introduction">
              Suzanne Déoux, Docteur en médecine ORL, Présidente de Bâtiment
              Santé Plus, Fondatrice de MEDIECO Conseil & Formation, nous
              explique l’ importance du renouvellement de l’air de nos
              appartements.
            </p>
            <blockquote>
              <p>
                Nous inhalons chaque jour 12m<sup>3</sup> d’air, dans les
                logements nous y passons 80 à 90% du temps.
              </p>
            </blockquote>
          </div>
        </div>
      </section>
    </>
  );
}

function EngaRow1() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Une ventilation renforcée</h3>
          <p>
            Cogedim est certifié NF Habitat, HQE, ce qui permet notamment
            d’assurer&nbsp;:
          </p>
          <ul>
            <li>
              un taux d’humidité près de 2 fois inférieur à celui des logements
              de moins de 10 ans non certifiés, afin de réduire les allergènes
              <sup>1</sup>&nbsp;;
            </li>
            <li>
              des débits de pression de VMC variables qui s’adaptent au taux
              d’humidité de la pièce.
            </li>
          </ul>
          <p>
            Cogedim respecte également le protocole Promevent sur l’ensemble des
            projets. Un bureau de contrôle certifie la qualité des systèmes de
            ventilation mécanique et leur mise en oeuvre. Ce diagnostic de
            ventilation renforcé garantit une excellente performance de
            ventilation et « empêche » l’apparition de moisissures.
          </p>
          <p class="legend">
            <sup>1</sup> Étude CERQUAL réalisée par le CSTB juillet 2017 –
            Comparaison avec la base ORTEC 2011-2016 (données issues des
            contrôles réglementaires de l’État).
          </p>
          <div class="download">
            <img
              src="/images/engagements/pdf-dossier-qualitel-benefices-certification.png"
              alt=""
              height="99"
              width="70"
              loading="lazy"
            />
            <div class="text">
              <h4>Les bénéfices concrets de la certification du logement</h4>
              <p>
                Découvrez l’étude Qualitel comparant les logements certifiés et
                non certifiés
              </p>
            </div>
            <a
              class="btn download-link"
              href="/images/engagements/Dossier-Qualitel-benefices-certification.pdf"
              target="_blank"
            >
              Télécharger le PDF{" "}
              <i aria-hidden="true" class="cog-icon">
                <IconArrowDownward />
              </i>
            </a>
          </div>
        </div>
        <div class="visual-part white-bg">
          <img
            src="/images/engagements/logo-nf-habitat.png"
            alt="NF Habitat"
            height="278"
            width="300"
            loading="lazy"
          />
          <img
            src="/images/engagements/logo-promevent.png"
            alt="Promevent"
            height="269"
            width="300"
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
}

function EngaRow2() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Des matériaux de finitions intérieures peu émissifs</h3>
          <p>
            Toutes les peintures utilisées dans nos logements sont labellisées
            A+ et limitent ainsi les émissions de COV et formaldéhydes.
          </p>
        </div>
        <div class="visual-part cyan-bg">
          <img
            src="/images/engagements/logo-certificat-aplus.png"
            alt="Certificat A+"
            height="219"
            width="416"
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
}

function EngaRow3() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>L'ajout de fenêtres oscillo-battantes</h3>
          <p>
            Les fenêtres sur allège des chambres seront équipées d’un châssis
            oscillo-battant.
          </p>
          <p>
            Cogedim intégrera également dans chaque logement un{" "}
            <strong>capteur de qualité de l’air</strong> intérieur.
          </p>
          <p class="excerpt">
            L’air intérieur est jusqu’à <strong>7x plus pollué</strong> que
            l’air extérieur
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-1-fenetre.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function VideoExcerpt() {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <section class="video-excerpt">
        <div class="video-row">
          <div class="visual">
            <img
              src={imgProxy(
                "/images/engagements/page-engagement-1-excerpt-video.jpg",
                `size:674:480/resizing_type:fill`,
              )}
              height="267"
              width="375"
              alt=""
            />
            <button
              class="btn btn-icon"
              onClick={() => {
                setMediaOverlay(
                  "embed",
                  `<iframe height="100%" width="100%" src="https://www.youtube.com/embed/217Gcn9uOQk" allowfullscreen />`,
                );
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPlayArrow />
              </i>
            </button>
          </div>
          <div class="video-details">
            <h3>Notre web-série “Tous engagés !”</h3>
            <h4>Veiller à la qualité de l’air intérieur</h4>
            <p>
              Focus sur le projet Coeur Mougins, labellisé Qualité d’air
              intAIRieur.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
