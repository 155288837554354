import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import Enga1Page from "~/components/Enga/Enga1Page";

export default function () {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Construire des biens qui font du bien, c’est notamment intégrer dans 100% de nos logements des systèmes de ventilation renforcée et des peintures labellisées qui limitent les émissions dans le respect des normes NF Habitat.",
    },
  };

  return (
    <>
      <Title>Engagement Cogedim - Une meilleure qualité de l’air</Title>
      <MetaTags tags={[metaDesc]} />
      <Enga1Page />
    </>
  );
}
